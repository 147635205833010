
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";

import User from "@/models/task/User";
import { Network } from "@ionic-native/network";
import { VisitorService } from "@/services/visitor/VisitorService";
import { FileHelper } from "@/utils/FileHelper";
import { SingleFile } from "@/models/file/Storage";
import { UserInfoUpdateService } from "@/services/mine/UserInfoUpdateService";

export default defineComponent({
  name: "AddVisitor",
  components: {
    IonPage,
    IonContent,
    IonText,
    IonInput,
    Header,
  },
  data() {
    const params = this.$route.query;
    const router = useRouter();
    const taskInfo: any = {
      priority: "0",
      projectId: "",
      executorUsers: [],
      partUsers: [],
    };
    const currentUser: User = SystemUtils.loginUser;
    const user: User = {
      id: "",
      pernr: "",
    };

    const fileHelper = new FileHelper();
    const storage: SingleFile = {
      folderId: "",
      systemId: "organization",
      busId: "",
      busTable: "ts_user",
      busTag: "ts_user",
    };
    return {
      fileHelper: fileHelper,
      photosMessage: new Array<any>(),
      storage: storage,
      folderId_photo: "",
      userInfo: user,
      UserInfoUpdateService: new UserInfoUpdateService(),
      prePath: "/ematrixjct/api",

      visitorService: new VisitorService(),
      router: useRouter(),

      id: params.id,
      name: "",
      mobilePhone: "",
      idNumber: "",
      orgName: "",
      actualTime: "",
      reason: "",
      userReply: "",
      photo: "",
      planTime: "",
      replyContents: new Array(),
    };
  },
  created() {
    this.replyContents.push("请稍等，我马上到！");
    this.replyContents.push("我马上下来接您！");

    this.initData();
  },
  methods: {
    initData() {
      let params = {
        id: this.id,
      };

      this.visitorService
        .visitorDetails(params)
        .then((res: any) => {
          if (res) {
            this.name = res.name;
            this.mobilePhone = res.mobilePhone;
            this.idNumber = res.idNumber;
            this.orgName = res.orgName;
            this.actualTime = res.actualTime;
            this.reason = res.reason;
            this.userReply = res.userReply;
            this.photo = res.photo;
            this.planTime = res.planTime;
          }
        })
        .catch(() => {});
    },
  },
});
